import axios from "axios";
import { jwtDecode } from "jwt-decode";
const Url = require("../../backendURL")

export const checkCustomerJWT = () => {
  const storedToken = localStorage.getItem("refreshToken");
  if (storedToken) {
    const decodedToken = jwtDecode(storedToken);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
      // Token has expired, redirect to login page or show modal
      // console.log("Token has expired");
      return false;
      // Redirect to login page or show modal
    } else {
      // Token is still valid, set it in state
      // console.log("Token is valid");
      return true;
    }
  } else {
    // Token is not stored, redirect to login page or show modal
    // console.log("Token not found");
    // Redirect to login page or show modal
    return false;
  }
};

// Function to check JWT validation and handle token refresh
// We will have to check if the Token is valid also, maybe we do this inside of the server
export const CustomerInterceptApiCall = async()=>{
  // Check if JWT is present and not expired
  const jwt = localStorage.getItem('token');
  if (jwt && !isCustomerTokenExpired(jwt)) {
    // Make the API call with the original request
    return true
  }

  // JWT is expired, try refreshing it
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken && !isCustomerTokenExpired(refreshToken)) {
    // Make an API call to refresh the JWT
    const refreshResponse = await axios.get(`${Url}accounts/login/refresh`, {
      headers: {
        "Authorization":`Bearer ${refreshToken}`
      },
    });
    if (refreshResponse.status === 200){      
      localStorage.setItem('token', refreshResponse.data.token);
      return true

    }
    else{
      return false
    }
  }
  else{
    return false
  }
}

// Helper function to check if a token is expired
function isCustomerTokenExpired(token) {
  if(token == "undefined"){
    return true
  }

  const expiration = jwtDecode(token).exp * 1000;
  return expiration < Date.now();
}
