/*******************************************************************************************************
 * Function/File Purpose: This file has functions which can be reused for another file with same format
 * Author: Aoi Kuriki
 * Date: 6/19/2023
 * 
 * **********************************
 * Contributor:
 * Date: 
 * What was Done: 
 * *******************************
 * Constributor:
 * Date:
 * What was done:
 * ********************************
 */


export const fixDateFormat = (inputDate) => {
  let dateString = inputDate
  if(dateString?.includes("AM" || "PM")){
    dateString = dateString.split(", ")[0]
  }
  if(dateString === "Cancelled" || dateString === "cancelled"){
    return dateString = "Cancelled"
  }
  const date = new Date(dateString)
  const formattedDate = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
  return formattedDate
}

export const fixTimeFormat = (inputTime) => {
  let time = inputTime
  if(time?.includes("/")){
    time = time.split(", ")[1]
  }
  if(time === "Cancelled" || time === "cancelled"){
    return time = "Cancelled"
  }

  const newTime = time.replace(":00 ", " ");
  return newTime
}



export const convertDateObject = (dateObject) => {
  if (typeof dateObject !== 'object' || !(dateObject instanceof Date)) {
    return dateObject;
  }
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

export const formatDateToMMDDYYYY = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
};

 export const navigateLink = (link, navigate) => {
  navigate(`/${link}`);
 }

 export const priceFormat = (price) => {
  let original = price / 100
  let result = original.toFixed(2)
  return result
 }

 export const convertNumberToTwoDigitFormat = (number) => {
  // Check if the number is between 1 and 9 (inclusive)
  if (number >= 1 && number <= 9) {
    // Pad the number with a leading '0' to make it two digits
    return `0${number}`;
  } else {
    // For numbers 10 to 12, return the number itself without any modification
    return number;
  }
}

export const SubTypesConvert = (intervalCount, interval) => {
  const key = intervalCount+interval
  const subTypesIntervalPicker = {
    "2week": "Bi-Weekly",
    "1month": "Monthly",
    "2month": "Bi-Monthly",
    "3month": "Three-Monthly",
    "4month": "Four-Monthly",
    "6month": "Half-Yearly",
    "1year": "Yearly"
  }
  return subTypesIntervalPicker[key]
}

export const monthlyConvert = (intervalCount, interval) => {
  const key = intervalCount+interval
  const subTypesIntervalPicker = {
    "2week": 0.5,
    "1month": 1,
    "2month": 2,
    "3month": 3,
    "4month": 4,
    "6month": 6,
    "1year": 12
  }
  return subTypesIntervalPicker[key]
}

export const DiscountConvert = (discountInterval) => {

  const discountIntervalPicker = {
    0 : "No Discount",
    1 : "Every Transaction",
    2 : "Every Other Transaction",
    3 : "Every 3rd Transaction",
    4 : "Every 4th Transaction",
    5 : "Every 5th Transaction",
    6 : "Every 6th Transaction",
    7 : "Every 7th Transaction",
    8 : "Every 8th Transaction",
    9 : "Every 9th Transaction",
    10 : "Every 10th Transaction",

  }
  if (discountInterval > 3) {
    return `Every ${discountInterval}th Transaction`;
  } else {
    return discountIntervalPicker[discountInterval];
  }
}

export const getDateTimeStamp = (dateField) => {
  const [dateString, timeString] = dateField.split(", ")
  const dateObject = new Date(dateString)

  return dateObject
}

export const findIndex = (data) => {
  const subscribingIndex = data.item.subTypes.findIndex(subType => subType._id === data.recurring.subTypeID);

  return subscribingIndex
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return ''; 
  }
  const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]} - ${match[3]}`;
  } else {
    return phoneNumber;
  }
};