import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { FaUserPlus, FaUser, FaStoreAlt, FaInbox } from "react-icons/fa";
import { TbShoppingCartCog} from "react-icons/tb";
import { Tooltip } from "react-tooltip";
import { MdOutlineMessage } from "react-icons/md";
import { getCustomerToken } from "../../Functions/Auth/getToken";
import axios from "axios";
import { map } from "../../icons/business/icons";
import { getAccountById } from "../../Customer_Module/CustomerStateManagement/CustomerState";
import useWindowSize from "../../Functions/Hooks/useWindowSize";

const NavLinks = ({ isLogin, isInBusiness }) => {

  const Url = require("../../backendURL");

  const navigate = useNavigate();

  const [notifs, setNotifs] = useState();
  const [counter, setCounter] = useState(0);
  const location = useLocation();
  const [isAddress, setIsAddress] = useState(null)

  let isMobile = useWindowSize();

  useEffect(() => {
    const fetchAddress = async() => {
      const res = await getAccountById();
      if (res?.address?.city && res?.address?.postal_code) {
        setIsAddress(res.address);
      } else {
        setIsAddress(null);
        console.error('Address or required fields are missing');
      }
    }
    fetchAddress()
  },[])

  // Fetches unseen messages for notifications in navbar
  const fetchUnseenMessages = async () => {

    setTimeout(() => {
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      if (isLoggedIn){
        setCounter(counter + 1);
      } 
    }, 10000);  

    if (!location.pathname.includes("chatbox")){
      const userId = localStorage.getItem("userId");
      const token = await getCustomerToken("/customer/home")
      const notifData = await axios.get(`${Url}chatbox/get-notifs/buyer/${userId}`, 
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      setNotifs(notifData.data);
    }

  }

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    
    if (isLoggedIn){
      fetchUnseenMessages();
    }
  }, [counter]);

  

  // End of notification code

  let resetLocalStorage = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    navigate("/customer/home");
    window.location.reload(false);
  };

  // User Details Click Handler
  const handleNavClick = (route) => {
    navigate(`/${route}`);
  };

  const sellerNavigation = () => {
    const isBusinessOwner = localStorage.getItem("isBusinessLoggedIn")
    if(isBusinessOwner == "true"){
      navigate("businessportal")
    }else{
      navigate("businessportal/landingPage")
    }
  }

  const scrollToZip = () => {
    let scrollOption = {
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    };


    const targetElement = document.getElementById("nearBusinesses");
  
    if (targetElement) {
      const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: offsetTop, ...scrollOption });
    }
  }

  return (
    <ul className="l-nav__links"> 
      {
        !isInBusiness && 
        <div className="l-nav__global--addressContainer">
        {map} 
        <div className="l-nav__global--address" onClick={()=> !isLogin ? scrollToZip() : navigate("/customer/user/shippingInfo")}>
        {
          !isLogin ? (
          <p >Guest</p>
          ) : isAddress ? (
          <>
          <label>{isAddress?.postal_code}</label>
          <p>{isAddress?.city}</p>
          </>
          ) : (
          <p>Add Address</p>
          )
        }      
              </div> 
            </div>
        }
      
      <li className="l-nav__links--business">
        {isInBusiness ? (
          // Render the icon when a business owner is logged in
          <FaStoreAlt 
          id="nav-link-icon-1" 
          onClick={() => handleNavClick("businessportal")}
          className="businessNavIcon" />
        ) : (
          // Render the text when not logged in as a business owner
          <span 
          onClick={() => sellerNavigation()}
          className="businessNavIcon">Sell</span>
        )}
      </li>

        {/* Stephen: Added link to navbar, couldn't go into NavLinks since that only renders on buyer side */}
        {isLogin && (
          <Link
            to={isInBusiness ? "/businessportal/chatbox" : "/customer/chatbox"}
            type="button"
            style={{textDecoration:"none"}}
          >
            <div style={{ position: 'relative', display: 'flex' }}>
              {
                isMobile ?
                <MdOutlineMessage className="customerNavIcon"/> :
                <span className="businessNavIcon" >Messages</span>
              }
              
              {
                notifs && notifs.length > 0 && (
                  <sup className="notificationFlag">
                    {notifs.length}
                  </sup>
                )
              }
            </div>
            
          </Link>
        )}
      <Tooltip
        anchorSelect="#nav-link-icon-1"
        content="Business Portal"
      />

      {/* If user is logged in, show user icon */}
      {isLogin ? (
        <li className="dropdownBtn" onClick={() => handleNavClick("customer/user")}>
          <FaUser className={`${isInBusiness ? 'businessNavIcon' : 'customerNavIcon'}`} />
          <ul className="dropdownMenu">
            <li className="dropdownMenuItems" onClick={resetLocalStorage}>
              Sign Out
            </li>
            <li
              className="dropdownMenuItems"
              onClick={() => handleNavClick("businessportal")}
            >
              Accounts
            </li>
          </ul>
        </li>
      ) : (
        <li id="nav-link-notLoggedIn">
          <FaUserPlus
            className={`${isInBusiness ? 'businessNavIcon' : 'customerNavIcon'}`}
            onClick={()=>handleNavClick("customer/login")}
            // onClick={() => setModalToggle(true)}
          />
        </li>
      )}
        <Tooltip
          anchorSelect="#nav-link-notLoggedIn"
          content="Log In"
        />
        
                {/* MySubscriptions */}
                {
          !isInBusiness&&(
            <li className="l-nav__links--mySubscriptions">
              <Link to="/customer/mySubscriptions" type="button" >
                <TbShoppingCartCog
                className='customerNavIcon'
                id="nav-link-mySubscriptions"
                 />
              </Link>
            </li>
          )
        }
        <Tooltip
          anchorSelect="#nav-link-mySubscriptions"
          content="My Subscriptions"
          // style={{ backgroundColor: "white", color:"#e5704c"}}
        />

    </ul>
  );
};

export default NavLinks;
