import React, { useEffect, useRef, useState } from 'react'
import NavIcon from '../../Component/Atom/NavIcon'
import { avatar, customerHome, person } from '../../icons/business/icons'
import { CSSTransition } from 'react-transition-group'
import { useNavigate } from 'react-router-dom'
import { Link, useLocation } from "react-router-dom";
import { MdOutlineMessage } from "react-icons/md";
import { getBusinessToken } from "../../Functions/Auth/getToken";
import axios from "axios";


const NavLink = () => {
  const Url = require("../../backendURL");
  const [open, setOpen] = useState(false)
  const [activeTarget, setActiveTarget] = useState("")
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0 });
  const [counter, setCounter] = useState(0);
  const location = useLocation();

  const navigate = useNavigate()

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      setDropdownPosition({ x: rect.left });
    }
  }, [open]);

  const [notifs, setNotifs] = useState([]);

  // Auto Refresh unseen messages
  const fetchUnseenMessages = async () => {

    setTimeout(() => {
      const isLoggedIn = localStorage.getItem("isBusinessLoggedIn");
      if (isLoggedIn){
        setCounter(counter + 1);
      } 
    }, 10000);  

    if (!location.pathname.includes("chatbox")){
      const userId = localStorage.getItem("businessId");
      const token = await getBusinessToken()
      const notifData = await axios.get(`${Url}chatbox/get-notifs/seller/${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      setNotifs(notifData.data);
    }
  }

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isBusinessLoggedIn");
    if (isLoggedIn){
      fetchUnseenMessages();
    }
  }, [counter]);

  // End of notification code

  const DropdownContent = ({children}) => {
    return (
      <div onClick={()=>setOpen(false)} className="overlay--noBack">
      <div  className='l-dropdown' onClick={(e)=> e.stopPropagation()} style={{ right: dropdownPosition.x }}>
          {children}
      </div> 
      </div>
    )
  }

  const DropdownItem = ({children, func}) => {
    return (
      <h6 onClick={func} className='c-dropdownMenu'>{children}</h6>
    )
  }

  const signOut = () => {
    navigate(`/businessportal/registrationPage`)
    localStorage.removeItem("B-token")
    localStorage.removeItem("B-refreshToken")
    localStorage.removeItem("IsOwner")
    localStorage.removeItem("B-refreshToken")
    localStorage.removeItem("isBusinessLoggedIn")
    localStorage.removeItem("businessId")
    setOpen(false)
  }

  return (
    <aside className='l-navLink'>
      <NavIcon icon={customerHome}link="customer/home">
      </NavIcon>
      <NavIcon 
      icon={avatar} 
      open={open}
      setOpen={setOpen}
      ref={dropdownRef}>
        {
          open && 
          <DropdownContent>
            <DropdownItem func={signOut}>Sign Out</DropdownItem>
          </DropdownContent>
        }
      </NavIcon>

      {/* Stephen: Added link to navbar, couldn't go into NavLinks since that only renders on buyer side */}

      {localStorage.getItem("isBusinessLoggedIn") ? (
        <Link to="/businessportal/chatbox" type="button">
          <div style={{ position: "relative", display: "flex" }}>
            <MdOutlineMessage className="messageIconForBusinessSide" />
            {notifs && notifs.length > 0 && (
              <sup className="notificationFlag">{notifs.length}</sup>
            )}
          </div>
        </Link>
      ) : (
        ""
      )}

    </aside>
  )
}

export default NavLink
