import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { navigateLink } from "../../Customer_Module/utilities/global_function";

const Searchbar = () => {

  const navigate = useNavigate()
  const ref = useRef()


  // to make the searchbar just static for now
  const handleSubmit = (e) => {
    e.preventDefault()
    const searchQuery = ref.current.value.toLowerCase()
    navigateLink(`customer/sortBy/${searchQuery}`,navigate)
  };

  return (
    <form className="c-search__container" onSubmit={handleSubmit}>
      <label htmlFor="customer/sortBy/"></label>
      <input
        className="c-search__input"
        type="text"
        ref={ref}
        placeholder="Search Subscriptions..."
        
      />
    </form>
  );
};

export default Searchbar;
