import React from 'react'
import { Link } from 'react-router-dom';
import { FaTiktok, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import "../Styles/Global.css"

const Footer = () => {
  return (
    <div className="footerContainer">
        <div className="footerContainerWrap">
            <div className="footerLinksWrap">
                <div className="footerHeading">
                    <Link to="/footer/our-story" className="footerLink">Our Story</Link>
                    <Link to="/footer/our-mission" className="footerLink">Our Mission</Link>
                    <Link to="/footer/meet-the-team" className="footerLink">Meet The Team</Link>
                    <Link to="/footer/help-center" className="footerLink">Help Center</Link>
                    <Link to="/" className="footerLink">Information</Link>
                </div>
            </div>
            <section className="socialMediaWrap">
                <div className="socialIcons">
                    <a href="https://www.instagram.com/subitt.io/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Instagram"
                    className="socialIconLink">
                        <FaInstagram />
                    </a>
                    <a href="https://www.twitter.com/wearesubitt"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Twitter"
                    className="socialIconLink">
                        <FaTwitter />
                    </a>
                    <a href="https://www.tiktok.com/@subitt.io"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="TikTok"
                    className="socialIconLink">
                        <FaTiktok />
                    </a>
                    <a href="https://www.linkedin.com/company/subitt/about/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="LinkedIn"
                    className="socialIconLink">
                        <FaLinkedin />
                    </a>
                </div>
            </section>
        </div>
        <div className="footerRights">
            <div className="websiteRights">
                <p>{new Date().getFullYear()} Subitt LLC.</p>
                <Link to="/footer/terms-of-use" className="footerLink">Terms of Use</Link>
                <Link to="/footer/privacy-policy" className="footerLink">Privacy Policy</Link>
                <Link to="/footer/contact-us" className="footerLink">Contact Us</Link>
            </div>
        </div>
    </div>
  )
}

export default Footer
