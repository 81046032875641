import React, { useState } from "react";
import Navbar from "./Navbar";

const Header = () => {
  const [modalToggle, setModalToggle] = useState(false);
  const handleModalToggle = (value) => {
    setModalToggle(value);
  };

  return (
    <header>
      <Navbar setModalToggle={handleModalToggle} />
    </header>
  );
};

export default Header;
