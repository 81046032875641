const state = process.env.REACT_APP_STATE ?? "";
var backendURL;

if (state == "DEVELOPMENT") {
    // Development URL
    backendURL = "https://subitt-backend-development.herokuapp.com/";
}
else if (state == "STAGING") {
    // Staging URL
    backendURL = "https://subitt-backend-staging.herokuapp.com/";
}
else if (state == "PRODUCTION") {
    // Production URL
    backendURL = "https://subitt-backend-production.herokuapp.com/";
}
else if (state == "LOCAL") {
    // Local URL, for backend and fullstack development
    backendURL = "http://localhost:8080/";
}
else {
    // Default if state is not defined
    backendURL = "https://subitt-backend-staging.herokuapp.com/";
}

// Export the URL
module.exports = backendURL;