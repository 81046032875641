export const categories = [
  "Auto",
  "Baby",
  "Beauty",
  "Cleaning",
  "Childcare",
  "Education",
  "Fitness",
  "Food",
  "Healthcare",
  "House",
  "Landscaping",
  "Money",
  "Outdoor",
  "Pet",
  "Sweets",
  "Tech"
];