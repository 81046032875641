import React from "react";

const LoadingDot = () => {
  return (
    <div className="p-loading__loading">
      <div className="p-loading__dod"></div>
      <div className="p-loading__dod"></div>
      <div className="p-loading__dod"></div>
      <div className="p-loading__dod"></div>
      <div className="p-loading__dod"></div>
    </div>
  );
};

export default LoadingDot;
