import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavIcon = ({ icon, children, link, open, setOpen, dropdownHandler }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (link) {
      navigate(link);
    } else {
      setOpen(!open)
    }
  };

  return (
    <>
    <div className='c-navIcon' onClick={handleClick}>
      {icon}
    </div>
    {open && children}
    </>
  );
};

export default NavIcon;
