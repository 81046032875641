/*
===================================================
Initial Information
==========
File Content: This file is for loading. using "react-loading" package("https://github.com/fakiolinho/react-loading"). When FE is retrieving data from DB, this file should be working
Author: Aoi Kuriki
File Created Date: 2023/02/10
===================================================
Revision History
==========
Author: firstName, lastName
Revised Date: yyyy/mm/dd
Revised Points: Be specific, Write down the reason you revised, HOW and WHERE, if there are additional problems or considerations, write down here.
==========
Revision 2 here
===================================================
Make sure each function and return also have specific comment to explain, especially To Do, Problem, State, Relationship with other files.

*/

import React from "react";
import SubittLogo from "../../Assets/subittSkeltonLogo.png"
import LoadingDot from "./LoadingDot";

const Loading = () => {
  return (
    <section className="p-loading__container">
      <div className="p-loading__imgContainer">
      <img src={SubittLogo} alt="subitt loading" />
      </div>
      <LoadingDot />
    </section>
  );
};

export default Loading;
